//Redux
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { connect, Provider } from 'react-redux';
import thunk from 'redux-thunk';
//React
import ReactDOM from 'react-dom';
import React from 'react';
//DnD
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';
//I18N
import { IntlProvider } from 'react-intl';

//https://github.com/react-dnd/react-dnd/issues/262

class DnDContextHolder extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return this.props.children;
    }
}

const ContextHolder = DragDropContext(HTML5Backend)(DnDContextHolder);

const I18NProvider = connect((state) => state["i18n"])(IntlProvider); //TODO: "i18n" move to constants

//Registers

const reducers = {};
const initialState = {};

export function registerReducer(stateKey, reducerFunction, state) {
    reducers[stateKey] = reducerFunction;
    if (state) {
        initialState[stateKey] = state;
    }
}

const components = {};

export function registerComponent(attr, factoryFunction) {
    components[attr] = factoryFunction;
}

const eventHandlers = [];

export function registerEventHandler(element, event, handlerFunction) {
    eventHandlers.push({ element, event, handlerFunction });
}

function createJqueryEventHandler(store, handlerFunction) {
    return (evt, extra) => {
        handlerFunction(store, evt, extra);
    }
}

let connectFunction = null;
let timer = null;
export function manualConnect(domElement, attr) {
    if (connectFunction != null) {
        connectFunction(domElement, attr);
        return;
    }
    if (timer != null) {
        window.clearInterval(timer);
    }
    /**Wait until connect function will be defined */
    timer = window.setInterval(() => {
        if (connectFunction == null) {
            return;
        }
        connectFunction(domElement, attr);
        window.clearInterval(timer);
        timer = null;
    }, 50);
}

//When document is ready
$(document).ready(() => {

    //Init clipboard
    const clipboard = new Clipboard('.clipboard');

    clipboard.on('success', function (e) {
        console.info('Action:', e.action);
        console.info('Text:', e.text);
        console.info('Trigger:', e.trigger);

        e.clearSelection();
    });

    clipboard.on('error', function (e) {
        console.error('Action:', e.action);
        console.error('Trigger:', e.trigger);
    });

    console.log(initialState);

    //Create root reducers from all modules
    const rootReducer = combineReducers(reducers);

    //For redux devtools in chrome
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    //Create store
    const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(thunk)));

    //Store connect function
    connectFunction = function (domElement, attr) {
        //Scan all registered components
        const factoryFunction = components[attr];
        const comp = factoryFunction(domElement);
        if (!comp) {
            return;
        }
        ReactDOM.render(
            <ContextHolder>
                <Provider store={store}>
                    <I18NProvider>{comp}</I18NProvider>
                </Provider>
            </ContextHolder>,
            domElement
        );
        return;
    }

    //Scan all registered components
    for (let attr in components) {
        const factoryFunction = components[attr];
        $(`[${attr}]`).each((_, domElement) => {
            const comp = factoryFunction(domElement);
            if (!comp) {
                return;
            }
            ReactDOM.render(
                <ContextHolder>
                    <Provider store={store}>
                        <I18NProvider>{comp}</I18NProvider>
                    </Provider>
                </ContextHolder>,
                domElement
            );
        });
    }

    //Register global event handlers
    for (const handler of eventHandlers) {
        $(handler.element).on(handler.event, createJqueryEventHandler(store, handler.handlerFunction));
    }
});